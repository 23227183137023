<template>
  <div id="email-app" class="flex relative overflow-hidden">
    <!----------- USERS ------------->
    <div class="w-full sm:w-3/12 d-theme-dark-bg">
      <UsersSection @selected="selectedUser = $event"/>
    </div>

    <!----------- ROLES ------------->
    <div class="w-full sm:w-3/12 d-theme-dark-bg">
      <RolesSection @selected="selectedRole = $event" :selectedUser="selectedUser" :updatedRole="updatedRole"/>
    </div>

    <!----------- PERMISSIONS ------------->
    <div class="w-full sm:w-6/12 d-theme-dark-bg">
      <PermissionsSection @saved="updatedRole = $event" :selectedRole="selectedRole"/>
    </div>
  </div>
</template>

<script>
import UsersSection from '@/views/pages/general/access-control/users/UsersSection'
import RolesSection from '@/views/pages/general/access-control/roles/RolesSection'
import PermissionsSection from '@/views/pages/general/access-control/permissions/PermissionsSection'
import moduleAccessControl from '@/store/modules/general/access_control/access-control.store'

export default {
  name: 'AccessControl',
  components: {
    RolesSection,
    UsersSection,
    PermissionsSection
  },
  data () {
    return {
      selectedUser: null,
      selectedRole: null,
      updatedRole: null
    }
  },
  methods: {

  },
  beforeCreate () {
    this.$store.registerModule(['general', 'accessControl'], moduleAccessControl)
  },
  beforeDestroy () {
    this.$store.commit('general/accessControl/RESET_STATE')
    this.$store.unregisterModule(['general', 'accessControl'])
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
