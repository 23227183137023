<template>
  <div>
    <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex border items-center">
        <vs-input v-model="search" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Cari Nama Akses" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
      </div>
      <div
        class="email__actions flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
        <div class="flex">
          <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
          <feather-icon @click="toggleExpandAll(null)" :icon="isExpandAll ? 'ChevronsUpIcon' : 'ChevronsDownIcon'" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
        </div>
        <div class="flex">
          <vs-button :disabled="!isAnyUnsavedChanges || this.isSaving" @click="save()" class="px-2" color="success" size="small" type="filled" icon-pack="feather" icon="icon-save">
            <span v-if="isSaving" class="animate-pulse">Menyimpan...</span>
            <span v-if="!isSaving">Simpan</span>
          </vs-button>
        </div>
      </div>
      <component :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
        <div class="w-full h-full flex items-center" v-if="!selectedRole">
          <p class="w-full text-sm text-center">Silahkan pilih <span class="font-semibold">Role</span> terlebih dahulu.</p>
        </div>
        <div class="w-full h-full flex items-center" v-if="isLoading || listPermissionsGrouped.length === 0">
          <span class="w-full text-center">{{ isLoading ? 'Loading...' : 'Tidak ada data.' }}</span>
        </div>
        <div class="w-full px-3 py-2 border border-solid border-r-0 border-l-0 border-t-0 d-theme-border-grey-light" v-if="selectedRole && !isLoading && listPermissionsGrouped.length > 0">
          <div class="flex justify-between">
            <p class="text-xs">Total {{ permissions.length }} permissions in {{ permissionsGrouped.length }} groups</p>
            <div class="flex items-center">
              <p class="text-xs text-success font-bold">Role {{ selectedRole.kode || '' }}</p>
              <feather-icon icon="ArrowRightIcon" class="mx-2" svg-classes="h-4 w-4"/>
              <p class="text-xs text-success font-semibold">{{ checked.length }} Akses</p>
            </div>
          </div>
        </div>
        <transition-group name="list-enter-up" class="email__mails" tag="ul" appear v-if="selectedRole && !isLoading">
          <!--parent content-->
          <li class="cursor-pointer mail-item-border-bottom select-none" v-for="item in listPermissionsGrouped" :key="item.uuid">
            <div class="mail__mail-item sm:px-4 px-2 pt-2 pb-1" @click="item.expanded = !item.expanded" @contextmenu.prevent="onGroupRightClicked(item)">
              <div class="flex py-2">
                <div class="flex w-full justify-between items-center">
                  <div class="mail__details flex">
                    <feather-icon :icon="item.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                    <h6 class="font-medium">{{ item.name }}</h6>
                  </div>
                </div>
                <div class="flex">
                  <span class="text-xs whitespace-no-wrap mr-2">{{ groupCheckedCount(item) }} / {{ item.items.length }} checked</span>
                  <feather-icon :icon="item.expanded ? 'ChevronUpIcon' : 'ChevronDownIcon'" svg-classes="h-5 w-5"/>
                </div>
              </div>
              <!--child content-->
              <div v-if="item.expanded" class="mt-2" @contextmenu.stop>
                <div v-for="item in item.items" :key="item.uuid" @click.stop class="mail-item-border-top sm:px-4 px-2 py-2 hover-bg-success-transparent-25">
                  <div class="flex">
                    <vs-checkbox v-model="checked" :vs-value="item.name" color="success" :disabled="!selectedRole.editable"/>
                    <div class="flex w-full justify-between items-center">
                      <div class="mail__details">
                        <h6 class="font-medium text-sm">{{ item.alias }}</h6>
                        <span v-if="item.group_sub" class="mr-1 whitespace-no-wrap opacity-75 justify-center p-1 text-black font-bold text-xxs bg-grey rounded">
                          {{ item.group_sub }}
                        </span>
                        <span class="text-xs bg-ema p-1">{{ item.name }}</span>
                      </div>
                      <div class="flex items-center">
                        <vx-tooltip v-if="item.description" :text="item.description">
                          <feather-icon icon="InfoIcon" svg-classes="h-5 w-5"/>
                        </vx-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </component>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AclRepository from '@/repositories/general/acl-repository'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'PermissionsSection',
  props: ['selectedRole'],
  components: {
    VuePerfectScrollbar
  },
  mounted () {
    this.getPermissions()
  },
  watch: {
    selectedRole (role) {
      if (!role) return
      this.checked = _.map(role.permissions, item => item.name)
    }
  },
  computed: {
    isAnyUnsavedChanges () {
      if (!this.selectedRole) return false
      const currentRolePermissions = _.map(this.selectedRole.permissions, item => item.name)
      return !_.isEqual(_.sortBy(currentRolePermissions), _.sortBy(this.checked))
    },
    listPermissionsGrouped () {
      // if search
      const search = this.search ? this.search.toLowerCase() : null
      if (search != null) {
        const permissions = this.permissions
        const filtered = _.filter(permissions, item => {
          return item.name.toLowerCase().includes(search) || item.alias.toLowerCase().includes(search) || item.group.toLowerCase().includes(search)
        })
        const groups = _.groupBy(filtered, item => item.group)
        return _.map(groups, (item, index) => {
          return {
            uuid: uuid(),
            name: index,
            items: item,
            expanded: true
          }
        })
      }

      return this.permissionsGrouped
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return {
        maxScrollbarLength: 100,
        wheelSpeed: 0.60
      }
    }
  },
  data () {
    return {
      search: null,
      isLoading: false,
      isSaving: false,
      isExpandAll: true,
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      permissions: [],
      permissionsGrouped: [],
      checked: []
    }
  },
  methods: {
    getPermissions () {
      this.isLoading = true
      AclRepository.getPermissions()
        .then(response => {
          const items = response.data
          const groups = _.groupBy(items, item => item.group)
          this.permissions = items.map(item => {
            item.uuid = uuid()
            return item
          })
          this.permissionsGrouped = _.map(groups, (item, index) => {
            return {
              uuid: uuid(),
              name: index,
              items: item,
              expanded: false
            }
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    save () {
      this.isSaving = true
      const params = { id_role: this.selectedRole.id, permissions: this.checked }

      AclRepository.updateRolePermissions(params)
        .then(response => {
          const updatedRole = response.data.role
          this.emitOnSaved(updatedRole)
          this.notifySuccess('Berhasil memperbaharui hak akses untuk role ' + this.selectedRole.name)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    onGroupRightClicked (group) {
      if (!this.selectedRole.editable) return
      const permissions = _.map(group.items, item => item.name)
      if (this.groupCheckedCount(group) === permissions.length) {
        // remove
        this.checked = _.difference(this.checked, permissions)
      } else {
        // push
        _.each(permissions, item => this.checked.push(item))
        this.checked = _.uniq(this.checked)
      }
    },

    refresh () {
      this.selected = null
      this.getPermissions()
    },

    toggleExpandAll (isExpand = null) {
      const expanded = isExpand !== null ? isExpand : !this.isExpandAll
      _.each(this.permissionsGrouped, item => {
        item.expanded = expanded
      })
      this.isExpandAll = expanded
    },

    groupCheckedCount (group) {
      const permissions = _.map(group.items, item => item.name)
      const sameItems = _.intersection(this.checked, permissions)
      return sameItems.length
    },

    emitOnSaved (updatedRole) {
      this.$emit('saved', updatedRole)
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
